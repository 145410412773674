.RationField {
  label {
    font-size: 1rem;
    color: var(--gray);
    &::after {
      content: ":";
    }
  }
  padding: 2px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
}
