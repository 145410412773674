.RationFooter {
  min-height: 100px;
  background: linear-gradient(var(--lightblack), var(--black));
  padding: 32px;
  a {
    margin-top: 12px;
    display: block;
    color: var(--lightgray);
    text-decoration: none;
    &:hover {
      color: var(--lightestgray);
      text-decoration: underline;
    }
  }
}
