.PresetLink {
  flex: 1;
  min-width: 250px;
  max-width: 250px;
  min-height: 60px;
  padding-bottom: 16px;
  overflow: hidden;

  @media only screen and (max-width: 620px) {
    max-width: unset;
  }
}
