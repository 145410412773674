.RationText {
  word-wrap: break-word;
  white-space: normal;

  &.RationText_secondary {
    color: var(--gray);
    font-size: 1rem;
  }
  &.RationText_error {
    color: red;
    font-size: 1rem;
  }
}
