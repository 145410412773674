.RationButton {
  margin: 0px;
  padding: 8px;
  border-radius: 2px;
  font-size: 1rem;
  &.RationButton_normal {
    border: solid 1px var(--primary-blend);
    background: var(--primary);
    color: var(--white);
    transition: background 0.1s;
    &:hover {
      background: rgba(var(--highlight-rgb), 0.6);
    }
    &:active {
      background: rgba(var(--highlight-rgb), 0.6);
    }
  }

  &.RationButton_secondary {
    border: solid 1px var(--lightgray);
    background: var(--lightergray);
    color: var(--darkgray);
    transition: background 0.1s;
    &:hover {
      background: var(--lightestgray);
    }
  }

  &.RationButton_link {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    color: var(--darkgray);
    text-decoration: underline;
    &:hover {
      color: var(--gray);
    }
    &:active {
      color: var(--gray);
    }
  }

  &.RationButton_dark {
    border: solid 1px var(--darkgray);
    background: var(--lightblack);
    transition: background 0.1s;
    color: var(--lightgray);
    &:hover {
      background: var(--darkgray);
      border: solid 1px var(--gray);
      color: var(--white);
    }
    &:active {
      background: var(--darkgray);
      border: solid 1px var(--gray);
      color: var(--white);
    }
  }
}
