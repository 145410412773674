body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --highlight: #00008b;
  --highlight-rgb: 0, 0, 139;

  --primary: #00008b;
  --primary-blend: #006;

  --one: #001861;
  --two: #2e008b;
  --three: #008b8b;
  --four: #006161;

  --black: #111111;
  --lightblack: #222222;

  --darkgray: #444444;
  --gray: #666666;

  --lightgray: #dcdcdc;
  --lightergray: #e8e8e8;
  --lightestgray: #f5f5f5;

  --white: #ffffff;

  background: linear-gradient(#fafafa, #f0f0f0);
  color: var(--lightblack);
}
