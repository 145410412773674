.RationTitle {
  margin: 16px 0px;
  color: var(--darkgray);
  font-size: 1rem;
  &.RationTitle_primary {
    display: inline-block;
    font-size: 1.5rem;
    color: var(--gray);
    margin: 0;
    margin-top: 16px;
    margin-bottom: 32px;
  }
}
