.RationTable_table {
  width: calc(100% - 32px);
  overflow-x: auto;
  padding: 4px;
  white-space: nowrap;

  table,
  th,
  td {
    text-align: left;
    padding: 12px 8px;
    border: solid 1px var(--lightgray);
    border-collapse: collapse;
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background: var(--lightestgray);
      }
      &:nth-child(even) {
        background: var(--lightergray);
      }
    }
  }
  thead {
    tr {
      color: var(--gray);
      background: var(--lightergray);
    }
  }
}
