.RationPage {
  .RationPage_content {
    padding: 8px;
    padding-bottom: 0px;
    margin: auto;
    min-height: calc(100vh - 100px);

    .RationPage_childrenWithSidebar {
      margin-top: 48px;
      margin-left: 16px;
      margin-bottom: 64px;
      width: calc(100vw - 300px);
    }

    .RationPage_childrenWithoutSidebar {
      margin: auto;
      margin-top: 12px;
      margin-bottom: 64px;
      max-width: 900px;
    }

    .RationPage_childrenWithoutSidebar_small {
      margin: auto;
      margin-top: 12px;
      max-width: 500px;
      margin-bottom: 64px;
    }
  }
}
