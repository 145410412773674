.RationCreatableSelect {
  min-width: 280px;
  max-width: 600px;
  &.RationCreatableSelect_large {
    width: calc(100% - 200px);
  }
}

@media only screen and (max-width: 800px) {
  .RationCreatableSelect {
    min-width: 100%;
  }
}
