.RationHeader {
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 0px 24px;
  align-items: center;

  &.RationHeader_normal {
    background: linear-gradient(var(--lightblack), var(--black));
    border-bottom: solid 1px var(--gray);
    color: var(--lightgray);

    .RationHeader_ration {
      font-size: 1rem;
      font-weight: bold;
      font-weight: 800;
      font-weight: 1000;
      color: var(--lightergray);
      a {
        text-decoration: none;
        color: var(--lightergray);
        &:hover {
          color: var(--lightestgray);
        }
      }
    }

    a {
      text-decoration: none;
      color: var(--lightergray);
      &:hover {
        color: var(--gray);
      }
    }
  }

  &.RationHeader_light {
    background: linear-gradient(var(--lightblack), var(--black));

    .RationHeader_ration {
      font-size: 1rem;
      font-weight: bold;
      font-weight: 800;
      font-weight: 1000;
      color: var(--lightergray);
      a {
        text-decoration: none;
        color: var(--lightergray);
        &:hover {
          color: var(--lightestgray);
        }
      }
    }

    a {
      text-decoration: none;
      color: var(--lightestgray);
      &:hover {
        color: var(--white);
      }
    }
  }
}
