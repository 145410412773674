.PresetSidebar {
  min-width: 190px;
  width: 190px;
  background: linear-gradient(var(--lightblack), var(--black));
  border-right: solid 1px var(--darkgray);
  border-top: solid 1px var(--darkgray);
  border-bottom: solid 1px var(--darkgray);
  padding: 8px;
  margin-right: 32px;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
  min-height: 100vh;
  max-height: 2000px;
  margin-top: -8px;
  margin-left: -8px;
  overflow: auto;

  a {
    display: block;
    color: var(--gray);
    padding-bottom: 6px;
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: none;
    &:hover {
      color: var(--lightestgray);
      text-decoration: underline;
    }
    &.Link_selected {
      color: var(--lightergray);
    }
  }
}
