.RationInput {
  font-size: 1rem;
  border: solid 1px var(--lightgray);
  border-radius: 2px;
  padding: 4px;
  margin: 0px;
  min-height: 28px;
  border-radius: 4px;
  &:focus {
    outline: 2px solid var(--highlight);
  }

  &.RationInput_long {
    width: 600px;
  }
}
