.ItemGrid {
  padding: 8px;
  display: grid;

  &.ItemGrid_normal {
    grid-template-columns: 33% 33% 33%;

    @media only screen and (max-width: 920px) {
      grid-template-columns: 50% 50%;
    }

    @media only screen and (max-width: 620px) {
      grid-template-columns: 100%;
    }
  }

  &.ItemGrid_thin {
    grid-template-columns: 25% 25% 25% 25%;

    @media only screen and (max-width: 920px) {
      grid-template-columns: 33% 33% 33%;
    }

    @media only screen and (max-width: 620px) {
      grid-template-columns: 50% 50%;
    }

    @media only screen and (max-width: 300px) {
      grid-template-columns: 100%;
    }
  }
}
